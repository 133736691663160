import './index.css';

export default function Footer() {
    let projectLeader = [
        {
            'name' : 'Flikimax',
            'link' : 'https://flikimax.com/links'
        }, 
    ];
    
    let contributors = [
        {
            'name' : 'César Felipe López León',
            'link' : 'https://colombiavip.com'
        }
    ];

    return (
        <footer className="footer bd-footer p-3 p-md-5 mt-3 bg-light text-center border-top">
            <div className="container">
                <div className='row d-flex'>

                    <h6>Lider del proyecto</h6>
                    <div className='contributors col-lg-12 mb-2 mx-auto d-block d-lg-flex justify-content-center'>
                        {
                            projectLeader.map((contributor, index) => {
                                return (
                                    <div className='item-contributor' key={index}>
                                        <a
                                            className="mx-2 text-decoration-none link-dark text-black-50"
                                            aria-current="page" 
                                            href={contributor.link}
                                        >
                                            {contributor.name}
                                        </a>
                                    </div>
                                )
                            })
                        }      
                        
                    </div>

                    <h6>Contribuidores</h6>
                    <div className='contributors col-lg-12 mx-auto d-block d-lg-flex justify-content-center'>
                        {
                            contributors.map((contributor, index) => {
                                return (
                                    <div className='item-contributor' key={index}>
                                        <a
                                            className="mx-2 text-decoration-none link-dark text-black-50"
                                            aria-current="page" 
                                            href={contributor.link}
                                        >
                                            {contributor.name}
                                        </a>
                                    </div>
                                )
                            })
                        }    
                    </div>

                </div>
            </div>
        </footer>
    );
}
