


export default function NotFound() {

    

    return (
        <div>
            No se encontro nada.
        </div>
    );
    
}