import React, { useState } from 'react';
import { navItems, searchFilter } from './functions';
import { Link } from "react-router-dom";
import './index.css';


export default function Sidebar() {
    const [itemsDocs, setItemsdocs] = useState( navItems() );

    return (
        <aside className="sidebar">
            
            <nav className="navbar navbar-expand-lg d-flex d-lg-block">
                <form className="flex-fill">
                    <div className="mb-3">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="search-docs" 
                            placeholder="Buscar" 
                            onChange={ (e) => {
                                let items = searchFilter( e.target.value ); 
                                ( e.target.value !== '' ) ? setItemsdocs( navItems(items) ) : setItemsdocs( navItems() );
                            } }
                        />
                    </div>
                </form>

                {/* TOGGLE SIDEBAR MENU */}
                <button 
                    id="toggle-sidebar-menu"
                    className="navbar-toggler mb-4 py-0 fs-3"
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#sidebarDocsDropdown" 
                    aria-controls="sidebarDocsDropdown" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrows-expand" viewBox="0 0 16 16">
                            <path d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z"/>
                        </svg>
                    <span className="">
                        
                    </span>
                </button>

                <div className="collapse navbar-collapse" id="sidebarDocsDropdown">
                    <div className="accordion flex-fill" id="accordionPanelsStayOpenExample">
                        
                        <div className="ps-3 my-4">
                            <Link 
                                to="/docs"
                                className="text-decoration-none "
                            >
                                Documentación
                            </Link>
                        </div>
                        
                        { itemsDocs }
                    </div>
                </div>
            </nav>
            
        </aside>
    );
}
