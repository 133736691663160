
import { Link } from "react-router-dom";
import './slider.css';

export default function Slider () {
    return (
        <>  
            <div className="container-slider container-fluid p-0 d-grid">
                <div className='d-inline-block'>
                    <Link to="/docs" >
                        <img className='w-100 mb-4  ' src="https://wordpress-framework.com/wp-content/uploads/docs/home/slide-left.jpg" alt="" />
                    </Link>
                </div>

                <div className='d-inline-block'>
                    <Link to="/docs/orm/"  >
                        <img className='w-100 mb-4' src="https://wordpress-framework.com/wp-content/uploads/docs/home/slide-right.jpg" alt="" />
                    </Link>
                </div>
            </div>
        </>
    );
}









