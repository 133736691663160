export function removeAccents( string ) {
    if ( typeof string !== 'string' ) {
        return string;
    }

    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function searchString ( string, search ) {
    string = removeAccents(string);
    search = removeAccents(search);

    return string.toLowerCase().includes(search.toLowerCase());
}

