import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

// Markdown dependencies
import {unified} from 'unified'
import remarkParse from 'remark-parse'
import remarkSlug from 'remark-slug'
import remarkAutolinkHeadings from 'remark-autolink-headings'
import remarkRehype from 'remark-rehype'
import rehypeFormat from 'rehype-format'
import rehypeStringify from 'rehype-stringify'
import remarkGfm from 'remark-gfm'

// Componets
import NotFound from './../../Components/Layouts/Docs/NotFount.js';
import Sidebar from './../../Components/Sidebar';
import TableOfContent from './../../Components/TableOfContent';

import docsMap from './../../docsMap.json';


function markdownFileName ( urlRelative ) {
    let path = urlRelative.split('/').slice(1);
    path.shift();
    path.push('');

    let fileName = '/docs';

    if ( path.length === 0 ) {
        return `${fileName}/index.md`;
    }

    let temp = docsMap;
    let match = false;

    // eslint-disable-next-line array-callback-return
    path.map( (item) => {
        if ( !match ) {
            if ( item && temp[item] ) {
                if ( typeof temp[item] === 'object' ) {
                    temp = temp[item];
                    fileName += '/' + item;
                } else if ( typeof temp[item] === 'string' ) {
                    temp = temp[item];
                    fileName += '/' + item + '.md';
                    match = true;
                }
            } else {
                if ( typeof temp === 'object' ) {
                    fileName += '/index.md';
                    match = true;
                }
            }
        }
    });

    return fileName;
}



export default function Docs() {
    const location = useLocation();

    const [content, setContent] = useState("");
    useEffect(() => {
        fetch( markdownFileName(location.pathname) )
        .then(res => res.text())
        .then(text => {
            let content = NotFound;
            if ( !text.includes('<!DOCTYPE html>') ) {
                content = unified()
                .use(remarkParse)
                .use(remarkSlug)
                .use(remarkAutolinkHeadings)
                .use(remarkRehype)
                .use(remarkGfm) // Para las Tablas
                .use(rehypeFormat)
                .use(rehypeStringify)
                .processSync(text)
                .toString();
            }
            
            setContent( content );
        });
    }, [location.pathname]); 

    
    return (
        <div className="container-fluid"> 
            <div className="row">

                <div className="col-lg-3 order-1 order-lg-1">
                    <Sidebar />
                </div>

                <div className="col-lg-6 order-3 order-lg-2">
                    {
                        (typeof content === 'object') 
                        ? content 
                        : <div className="markdown" dangerouslySetInnerHTML={{__html: content}}></div>
                    }
                </div>

                <div className="col-lg-3 order-2 order-lg-3">
                    <TableOfContent content={content} />
                </div>

            </div>
        </div>
    );
}
