import React, { useState, useEffect } from 'react';

import { Headings, useIntersectionObserver, getNestedHeadings } from './functions';
import './index.css';

export default function TableOfContent(content) {
    const [activeId, setActiveId] = useState();
    const [nestedHeadings, setNestedHeadings] = useState([]);

    useEffect(() => {
      const headingElements = Array.from(
        document.querySelectorAll("h2, h3")
      );
  
      const newNestedHeadings = getNestedHeadings(headingElements);
      setNestedHeadings(newNestedHeadings);

    }, [content]); 

    useIntersectionObserver(setActiveId, content);

    return (
      <nav
        className="table-of-content mb-4 pb-2" 
        aria-label="Table of contents"
      >
        <h4>Tabla de contenido</h4>
        <Headings headings={nestedHeadings} activeId={activeId} />
      </nav>
    );
}
