import {
    BrowserRouter as Router, // Usar si el servidor esta correctamente configurado
    // HashRouter as Router, // De lo contrario, usar esta linea.
    Routes,
    Route,
} from "react-router-dom";

// Layouts
import LayoutApp from './../Components/Layouts/App';
import LayoutDocs from './../Components/Layouts/Docs';

// Pages
import Home from '../Pages/Home';
import Docs from '../Pages/Docs';

import NotFound from '../Pages/NotFound';


function App() {
    return (
        <Router>
            <Routes>

                <Route path="/" element={<LayoutApp />}>
                    <Route index element={<Home />} />

                    <Route path="*" element={<NotFound />} />
                </Route>

                <Route path="/docs" element={<LayoutDocs />}>
                    <Route index element={<Docs />} />
                    <Route path="*" element={<Docs />} />
                </Route>

            </Routes>
        </Router>
    );
}

export default App;
