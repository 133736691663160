export default function TopHeader() {
    return (
        <div className='container-fluid text-center'>
            <div className='row'>
                <div className="alert alert-warning py-2 m-0" role="alert">
                    La documentación aun esta en proceso, es posible que cambie en el futuro.
                </div>
            </div>
        </div>
    );
}
