import docsMap from './../../../docsMap.json';

export default function pageTitle ( urlRelative ) {
    let path = urlRelative.split('/').slice(1);
    path.shift();

    let temp = docsMap;
    let match = false;
    let title = '';

    // eslint-disable-next-line array-callback-return
    path.map( (item) => {
        if ( !match ) {
            if ( item && temp[item] ) {
                if ( typeof temp[item] === 'object' ) {
                    temp = temp[item];
                } else if ( typeof temp[item] === 'string' ) {
                    title = temp[item];
                    temp = temp[item];
                    match = true;
                }
            } else if ( typeof temp === 'object' ) {
                title = temp.title;
                match = true;
            }
        }
    });

    if ( !title ) {
        return 'Documentación';
    }

    return `${title} > Docs`;
}