import ItemsMenu from './ItemsMenu';

import './index.css';

export default function Header() {
    return (
        <header className="header shadow-sm sticky-top">
            <div className='container'>
                <div className='row'>
                    <ItemsMenu />
                </div>
            </div>
        </header>
    );
}
