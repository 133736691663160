// import React from 'react';
import { createRoot } from 'react-dom/client';

// Bootstrap
// eslint-disable-next-line no-unused-vars
import * as bootstrap from 'bootstrap';
// eslint-disable-next-line
import 'bootstrap/dist/css/bootstrap.min.css'; 

import './assets/css/index.css';
import App from './routers/App';

const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<App tab="home" />);
