import Slider from './slider';

export default function Home() {
    return (
        <>
            <Slider />

            <div className="container">
                <div className='row'>
                    <div className="col-lg-6 pe-lg-4">
                        <p>
                        El Framework es construido utilizando el propio entorno de WordPress para tener una alta compatibilidad y velocidad en el desarrollo.
    Se hace uso del patron de arquitectura mvc (modelo-vista-controlador) para una separación de conceptos entre lo que ve el usuario, el controlador y el negocio.
                        </p>
                    </div>
                    <div className="col-lg-6 pe-lg-4">
                        <p>
                        Esta liviana librería puede ser utilizada independiente al Framework, ser agregada como un plugin o como una librería mediante Composer.
    Facilitando el manejo de consultas simples o complejas y agilizando el proceso de desarrollo.
                        </p>
                    </div>
                </div>
            </div>
        </>

        
    );
}