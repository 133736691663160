import { Outlet, useLocation } from "react-router-dom";

import TopHeader from './TopHeader';
import Header from '../../Header';
import Footer from '../../Footer';

import './index.css'; 

import pageTitle from './functions';

export default function LayoutDocs() {
    const location = useLocation();
    document.title = pageTitle( location.pathname );

    return (
        <div className="Docs">
            <TopHeader />
            <Header />

            <div className="content mt-4">
                <Outlet />
            </div>
            
            <Footer />
        </div>
    );
}
