import { Link } from "react-router-dom";
import logo from "./../../assets/images/logo-white.svg";


export default function Logo() {
    return (
        <div className="app-logo py-2">
            <Link 
                to="/"
                className="nav-link text-white d-flex align-items-center"
                aria-current="page"
            >
                <img src={logo} className="App-logo me-2" alt="logo" width="30" height="30" />
                <span>WordPress Framework</span>
            </Link>
        </div>
    );
}
