import { Link } from "react-router-dom";
import { backToTop } from "./../Layouts/functions";
import { searchString } from './../helpers';
import docsMap from './../../docsMap.json';

// Se encarga gestionar que acordeones estan activos
// y que esta configuración sea persistente.
export function activeAccordions(item) {
    const expandedItems = localStorage.getItem('expandedItems');

    if ( expandedItems ) {
        const items = JSON.parse(expandedItems);
        items[item] = !items[item];
        localStorage.setItem( 'expandedItems', JSON.stringify(items) );
    } else {
        item = JSON.stringify({
            [item]: true
        });

        localStorage.setItem('expandedItems', item);
    }
}


export function searchFilter( search = '' ) {
    let filtered = {};
    
    if ( !docsMap ) {
        return filtered;
    }

    // eslint-disable-next-line array-callback-return
    Object.entries(docsMap).map( ( item, index ) => {
        filtered[ item[0] ] = {};
        filtered[ item[0] ].title = item[1].title;

        // Objecto principal > Level 1
        // eslint-disable-next-line array-callback-return
        Object.entries(item[1]).map( ( subItem ) => {
            if ( typeof subItem[1] === 'object' ) {
                filtered[ item[0] ][subItem[0]] = {};
                filtered[ item[0] ][subItem[0]].title = subItem[1].title;

                // Objecto principal > Level 2 
                // eslint-disable-next-line array-callback-return
                let twoLevel = Object.entries(subItem[1]).filter( ( lastItem ) => {
                    if ( searchString(lastItem[1], search) ) {
                        filtered[ item[0] ][ subItem[0] ][ lastItem[0] ] = lastItem[1];
                        
                        return {
                            [lastItem[0]]: lastItem[1]
                        };
                    }
                });

                if ( twoLevel.length === 0 ) {
                    delete filtered[ item[0] ][ subItem[0] ];
                }
            } else if ( typeof subItem[1] === 'string'  ) {
                if ( searchString(subItem[1], search) ) {
                    filtered[ item[0] ][ subItem[0] ] = subItem[1];
                }
            }
        });

        if ( 
            Object.entries( filtered[ item[0] ] ).length <= 1 
            && !searchString(item[1].title, search) 
        ) {
            delete filtered[ item[0] ];
        }
    });

    return filtered;
}

export function navItems( itemsObject = null, url = null ) {
    
    if ( !itemsObject ) {
        itemsObject = docsMap;
    }

    if ( !url ) {
        url = '/docs';
    }

    // Acordeones activos.
    const expandedItems = localStorage.getItem('expandedItems') ? JSON.parse(localStorage.getItem('expandedItems')) : {};

    let items = [];
    if ( itemsObject ) {
        // eslint-disable-next-line array-callback-return
        items = Object.entries(itemsObject).map( ( item, index ) => {

            if ( typeof item[1] === 'object' ) {
                // Si es el primer elemento, desplegarlo.
                const collapsed = ( expandedItems[item[0]] === true ) ? '' : 'collapsed';
                const show = ( expandedItems[item[0]] === true ) ? 'show' : '';

                return (
                    <div key={index} className="accordion-item">
                        <h4 className="accordion-header" id={`panelsStayOpen-heading-${index}`}>
                            <button 
                                className={`accordion-button ${collapsed}`}
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target={`#panelsStayOpen-collapse-${index}`}
                                aria-controls={`panelsStayOpen-collapse-${index}`}
                                onClick={() => activeAccordions(item[0])}
                            >
                                {item[1].title}
                            </button>
                        </h4>

                        <div 
                            id={`panelsStayOpen-collapse-${index}`}
                            className={`accordion-collapse collapse ${show}`} 
                            aria-labelledby="panelsStayOpen-headingOne"
                        >
                            <div className="accordion-body p-0">
                                <ul className="list-group list-group-flush">
                                    {navItems(item[1], `${url}/${item[0]}`)}
                                </ul>
                            </div>
                        </div>
                    </div>
                );
            } else if ( typeof item[1] === 'string' ) {
                let urlItem = `${url}/${item[0]}/`
                if ( item[0] === 'title' ) {
                    urlItem = url;
                }

                return (
                    <li 
                        className="list-group-item my-1"
                        key={index}
                    >
                        <Link 
                            to={urlItem}
                            className="text-decoration-none"
                            onClick={ () => {
                                backToTop();

                                const toggleSidebarMenu = document.getElementById("toggle-sidebar-menu");
                                if (window.getComputedStyle(toggleSidebarMenu).display === "none") {
                                    return;
                                }
                                toggleSidebarMenu.click();
                            } }
                        >
                            <span className="me-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </span>
                            {item[1]}
                        </Link>
                    </li>
                );
            }
        });
    }
    
    return items;
}
